@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@400;500;600;700&family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600&display=swap");

:root {
  --bs-body-font-family: "Plus Jakarta Sans", sans-serif !important;
}
body {
  font-family: "Plus Jakarta Sans", sans-serif !important;
}
body a {
  text-decoration: none;
}
#loginPAGE .tabs {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  border-radius: 5px;
}
#loginPAGE .tabs .tab {
  width: 50%;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
}
#loginPAGE .tabs .tab#active {
  color: white;
  /*background: #f0a13d;*/
  background: #f47647;
}
.desctiptionPageText {
  max-width: 480px;
  margin: 10px 0;
}
.qrLogin {
  position: absolute;
  right: 20px;
  top: 20px;
  max-width: 20vw;
  width: 150px;
}
.desctiptionPageText p {
  margin-bottom: 5px;
  font-size: 14px;
  text-align: left;
}

.desctiptionPageText a {
  font-weight: 700;
}
.cursorPointerdelete {
  cursor: pointer;
  color: red;
  font-weight: 600;
}
#loginPAGE .mainH1,
#maciejForm .mainH1 {
  font-family: "Baloo Da 2" sans-serif;
  color: rgba(51, 51, 51, 1);
  font-size: 56px;
  font-weight: 700;
}

#maciejForm .mainH1 {
  font-size: 40px;
  text-align: center;
}
#maciejForm form {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto;
  gap: 20px;
  align-items: center;
}
#loginPAGE {
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #eaeaea;
  padding-bottom: 5rem;
}
#loginPAGE .logo {
  max-width: 250px;
  margin-bottom: 2rem;
}
#loginPAGE form,
.addChild form,
#maciejForm form {
  background: white;
  padding: 50px;
  display: flex;
  flex-direction: column;
  border-radius: 26px;
  border: 1px solid #d6d6d6;
}
.addChild form input:not([type="checkbox"]) {
  width: 90% !important;
}

body .addChild form input.myDatepicker {
  width: 100% !important;
  max-width: 100%;
}

.addChild form {
  border: none;
}
#loginPAGE h2 {
  font-size: 25px;
  font-weight: 600;
}
.addChild h2 {
  font-weight: 600;

  font-size: 18px;
}
#loginPAGE .dividier,
.addChild .dividier {
  /*color: #f0a13d;*/
  color: #f47647;
  opacity: 1;
}

.images h2 {
  font-size: 14px;
}

button.download {
  border: none;
  border-radius: 10px;
  /*background: #f0a13d;*/
  background: #f47647;
  padding: 8px 12px;
  color: white;
}

#loginPAGE .logoRegister {
  max-width: 200px;
  margin-bottom: 2rem;
}

#loginPAGE form input:not([type="checkbox"]),
#formInputs form input:not([type="checkbox"]),
.addChild form input:not([type="checkbox"]) {
  width: 350px;
  max-width: calc(100% - 40px);
  border: 1px solid #eaeaea;
  height: 50px;
  padding: 10px 20px;
  color: #000000;
  border-radius: 10px;
}
#formInputs form hr {
  width: 390px;
  max-width: calc(100% - 40px);
}
#formInputs form button {
  width: 390px !important;
  max-width: calc(100% - 40px) !important;
}
#maciejForm form input:not([type="checkbox"]),
#maciejForm form textarea {
  width: 550px;
  max-width: calc(100% - 40px);
  border: 1px solid #eaeaea;
  height: 50px;
  padding: 10px 20px;
  color: #000000;
  border-radius: 10px;
}

.checkboxes .radioButton {
  width: 25px;
  border-radius: 50%;
  border: 2px solid #000000;
  background: white;
  height: 25px;
}
.registerLabel {
  width: 350px;
  max-width: 90vw;
  text-align: left;
}

.registerLabel a {
  font-weight: 700;
  padding: 0 !important;
  /*color: #f0a13d;*/
  color: #f47647;
}
.checkboxes .radioButton#active {
  /*background: #f0a13d;*/
  background: #f47647;
  /*border-color: #f0a13d;*/
  border-color: #f47647;
}
.diagnozaGO {
  /*color: #f0a13d;*/
  color: #f47647;
  font-size: 20px;
}
#wrapperContainer > div {
  width: 90%;
}
#wrapperContainer > div input {
  width: 100%;
}
.CalendarContainer-sc-1gorc7r-0 {
  box-shadow: 0px 10px 40px 0px #aeaeae33;
}
.checkbox input {
  width: 20px !important;
  height: 20px !important;
  /*accent-color: #f0a13d;*/
  accent-color: #f47647;
}
.checkbox label {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
.checkboxes input label {
  font-size: 20px !important;
}
.checkboxes .form-check {
  width: fit-content;
}
.addChild form .groupIcon {
  width: 10%;
}
#loginPAGE form input:focus,
#formInputs form input:focus,
.addChild form input:focus,
#maciejForm form input:not([type="checkbox"]):focus,
#maciejForm form textarea:focus {
  box-shadow: 0px 10px 40px 0px #aeaeae33;
  outline: none;
}

#maciejForm .registerLabel {
  width: 550px;
  max-width: 90vw;
}
.myInputFocus:focus {
  box-shadow: 0px 10px 40px 0px #aeaeae33 !important;
  outline: none !important;
}
.adminLoginPage#loginPAGE {
  min-height: fit-content;
  background: white;
  align-items: baseline;
  padding: 20px;
}
.adminLoginPage#loginPAGE form {
  border: none;
  padding: 0;
}
.adminLoginPage#loginPAGE form .input-group,
#formInputs form .input-group {
  flex-wrap: nowrap;
}
.adminLoginPage#loginPAGE form input,
#formInputs form input {
  width: 100%;
}

.filesPreview {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.filesPreview .filePriev {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.filesPreview .filePriev p {
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.filesPreview .filePriev button {
  width: 50px;
  padding: 5px;
}

.removeDiagnosis {
  color: red;
  font-size: 20px;
}

.logoLogin {
  max-height: 250px;
}
button.main {
  box-shadow: 0px 10px 40px 0px #aeaeae33;
  border: 1px solid #eaeaea;
  /*background: #f0a13d;*/
  background: #f47647;
  color: white;
  padding: 5px 10px;
  font-weight: 400;
  font-size: 16px;
  border-radius: 10px;
}
button.loginButton,
#formInputs form button {
  box-shadow: 0px 10px 40px 0px #aeaeae33;
  border: 1px solid #eaeaea;
  /*background: #f0a13d;*/
  background: #f47647;
  color: white;
  width: 100%;
  padding: 13px 0;
  font-weight: 600;
  font-size: 18px;
  border-radius: 10px;
}
#maciejForm button.loginButton {
  width: 300px;
  max-width: 90vw;
}
#loginPAGE form button.loginButton,
.addChild form button.loginButton {
  box-shadow: 0px 10px 40px 0px #aeaeae33;
  border: 1px solid #eaeaea;
  /*background: #f0a13d;*/
  background: #f47647;
  color: white;
  width: 100%;
  padding: 13px 0;
  font-weight: 600;
  font-size: 18px;
  border-radius: 10px;
}
.addChild form button.loginButton {
  width: 300px;
  max-width: 90vw;
  /*background: #1e3c3c;*/
  background: #2f3439;
}
#loginPAGE form h1 {
  font-size: 35px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
}

#loginPAGE form h3 {
  font-size: 25px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

#loginPAGE form a,
.addChild form a {
  color: #333333;
  padding: 0 10px;
  text-decoration: none;
}

#loginPAGE .register a {
  font-weight: 600;
  padding: 0;
}

#loginPAGE .groupIcon,
.addChild .groupIcon,
#formInputs form .groupIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
#loginPAGE .input-group input,
#formInputs .input-group input,
.addChild .input-group input {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#loginPAGE .groupIcon,
#formInputs .groupIcon,
.addChild .groupIcon {
  border: 1px solid #eaeaea;
  height: 50px;
  min-width: 40px;
  padding: 5px 10px;
  color: #b0a9a9;
  border-radius: 10px;
  border-right: none;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.logopanel {
  max-height: 80px;
}

.leftborder {
  /*border-left: 1px solid #f0a13d;*/
  border-left: 1px solid #f47647;
}

.buyProgram4 button {
  max-width: 200px;
  padding: 10px 20px;
}

.notfications {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.notification {
  padding: 20px;
  background: #eee;
  border-radius: 10px;
}

.notification .content {
  background: #eee;
  font-style: italic;
  font-size: 18px;
}

#header h1 {
  font-size: 46px;
  font-weight: 400;
  line-height: 62px;
  letter-spacing: -0.04em;
  text-align: left;
}

#header .childSelect::after {
  display: none !important;
}
#header .childSelect {
  border: 1px solid #2f2a2a;
  background: white;
  padding: 10px 20px;
  color: #2f2a2a;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  border-radius: 100px;
  letter-spacing: -0.02em;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
#header .childSelect i {
  font-size: 20px;
}

button.logout {
  /*border: 1px solid #1e3c3c;*/
  border: 1px solid #2f3439;
  background: white;
  padding: 10px 20px;
  color: #2f2a2a;
  /*background: #1e3c3c;*/
  background: #2f3439;
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  border-radius: 100px;
  letter-spacing: -0.02em;
}

.helloHeader {
  /*background-color: #f0a13d;*/
  background-color: #f47647;
  color: white;
}

.childDropdown ul {
  background: white;
  border-radius: 10px;
  text-align: left;
  box-shadow: 0px 10px 40px 0px #aeaeae33;
}
.childDropdown .dropdown-item:active {
  background: white;
  color: black;
}
.childDropdown .dropdown-item:hover,
.childDropdown .dropdown-item#active {
  /*color: #f0a13d;*/
  color: #f47647;
}

.childITEM {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

button.append {
  background: white;
  /*border: 1px solid #f0a13d;*/
  border: 1px solid #f47647;
  /*color: #f0a13d;*/
  color: #f47647;
  padding: 10px 20px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
}

.detailGO {
  /*color: #f0a13d;*/
  color: #f47647;
  padding-bottom: 3px;
  /*border-bottom: 1px solid #f0a13d;*/
  border-bottom: 1px solid #f47647;
}

.sidebarItem {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: top;
}

.sidebarIcon {
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  padding: 5px;
  border: 1px solid #d1d1d1;
  border-radius: 50%;
}
.sidebarIcon a {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebarItem#active .sidebarIcon {
  /*border-color: #f0a13d;*/
  border-color: #f47647;
  /*background: #f0a13d;*/
  background: #f47647;
}
.sidebarItem#active h2 {
  /*color: #f0a13d;*/
  color: #f47647;
}
.sidebar {
  /*background: #1e3c3c;*/
  background: #547d6c;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.sibarText #fontweight {
  font-weight: 800;
}
.images .col-6 {
  position: relative;
}

.removeGalleryItem {
  position: absolute;
  top: -10px;
  right: 0px;
  color: red;
  font-size: 20px;
  z-index: 1000;
  background: #f4f4f4;
  border-bottom-left-radius: 5px;
}
.sibarText #fontweight span {
  font-weight: 600;
  /*color: #f0a13d;*/
  color: #f47647;
}
.sibarText h2 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 5px;
  line-height: 35px;
  letter-spacing: -0.02em;
  text-align: left;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.sibarText .hidden {
  height: 0;
  overflow: hidden;
  transition: all 1s ease-in-out;
  animation: slidein 1s ease-in-out;
}

#contentPANEL {
  height: calc(100vh - 168px);
}

.scheduler p,
.scheduler button {
  font-family: "Plus Jakarta Sans", sans-serif !important;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  /*color: #f0a13d !important;*/
  color: #f47647 !important;
}
.sidebarItem a {
  color: white;
}
@keyframes slidein {
  from {
    height: 0;
  }

  to {
    height: 100px;
  }
}
.fileNAMEINPUT {
  font-size: 14px;
  margin-bottom: 0;
  height: 20px;
  overflow: hidden;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.imageInner {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
}

.imageInnerGallery {
  position: relative;
  width: 100%;
  padding-top: 75%; /* Adjust this to set the aspect ratio, e.g., 75% for 4:3 */
  overflow: hidden;
  background-color: #f0f0f0; /* Optional: background color for placeholder */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageInnerGallery img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageLabel {
  z-index: 1000;
  background: #DCDCDC;
  width: 100%;
  padding: 10px;
}

.images .image {
  cursor: pointer;
  width: 100%;
  display: flex;
  height: 150px;
  background: #dcdcdc;
  border-radius: 10px;
  display: "flex";
  justify-content: center;
  font-size: 20px;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.384), rgba(0, 0, 0, 0.637)),
    url("/public/logo1.3.png") no-repeat center center;
  background-size: cover;
}

.content table thead {
  background: #f9f1e8 !important;
}
.content table thead tr {
  padding: 0 5rem !important;
}
.content table thead th {
  background: #f9f1e8 !important;
}
.content .transparentBG table thead th,
.content .transparentBG table tbody th,
.content .transparentBG table tbody td {
  background: transparent !important;
  font-size: 12px;
  padding-left: 0 !important;
  text-align: center;
}

.content .transparentBG table tbody th .childITEM,
.content .transparentBG table tbody td .childITEM {
  background: transparent !important;
  font-size: 12px;
  padding-left: 0 !important;
  justify-content: center;
}

.content .transparentBG table thead {
  background: transparent !important;
}
.maxHeightIMG {
  max-height: 50px;
}
.ps-xl-5 .childITEM {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 200px;
  gap: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  background: #e7dcd0;
}
.filters .input-group {
  width: fit-content;
}

.sidebarItem .sibarText {
  width: calc(100% - 50px);
}
.sidebarItem .sibarText a {
  width: 100%;
}

.filters .groupIcon {
  border: 1px solid #eaeaea;
  height: 40px;
  background: white;
  padding: 5px 10px;
  color: #b0a9a9;
  border-radius: 10px;
  border-right: none;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.filters input {
  width: 300px;
  max-width: 90vw;
  border: 1px solid #eaeaea;
  height: 40px;
  padding: 10px 20px;
  color: #000000;
  border-radius: 10px;
}

.loaderContainer {
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  color: red;
  font-size: 18px;
}

.success {
  color: green;
  font-size: 18px;
}

.section {
  padding: 2rem;
  text-align: center;
}

.pages .numer#active {
  background: black;
  border-color: black;
  color: white;
}
.paginationTable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.pages .prev {
  padding: 5px 15px;
  cursor: pointer;
  border: 1px solid #dbdfea;
}

.selectPage {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.selectPage input {
  height: 30px;
  width: 50px;
}

.pages {
  display: flex;
  flex-direction: row;
  gap: 0;
}

.pages .numer {
  background: #fff;
  border: 1px solid #dbdfea;
  cursor: pointer;
  padding: 5px 10px;
}

.noChildren {
  min-height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.sectionsSwap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /*border-bottom: 1px solid #f0a13d;*/
  border-bottom: 1px solid #f47647;
}

.itemSwap {
  padding: 20px;
  text-align: center;
  font-size: 22px;
  width: 25%;
  cursor: pointer;
}
.itemSwap:hover,
.itemSwap#active {
  /*background: #f0a13d;*/
  background: #f47647;
  color: white;
}

.CalendarContainer-sc-1gorc7r-0 {
  z-index: 9999;
}

.section {
  background: #eaeaea;
  margin: 10px;
  font-size: 18px;
  border-radius: 10px;
}

.section .counter {
  font-size: 24px;
  font-weight: 700;
}

button.remove {
  background: rgb(172, 36, 36);
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  padding: 13px 0;
  font-weight: 600;
  font-size: 18px;
}

.notActiveChild {
  display: flex;
  gap: 30px;
  flex-direction: column;
  height: 50vh;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.notActiveChild h1 {
  font-size: 24px;
  font-weight: 500;
}

.css-s22wio > div:first-child {
  /*background: #f0a13d !important;*/
  background: #f47647 !important;
}
.css-nl6has .MuiButton-root.delete {
  font-weight: 600;
  font-size: 16px;
}
.css-nl6has .MuiButton-root.cancel {
  color: white !important;
  font-size: 16px;
  font-weight: 600;
}
.css-1db085k-MuiSvgIcon-root {
  color: black !important;
}

.css-1ps6pg7-MuiPaper-root,
.css-15fehp8-MuiTypography-root-PrivatePickersMonth-root.Mui-selected,
.css-3eghsz-PrivatePickersYear-button.Mui-selected,
.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-aoeo82 {
  /*background: #f0a13d !important;*/
  background: #f47647 !important;
}
.font16 {
  font-size: 15px !important;
}

.myspecialRow {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

#modal button {
  border-radius: 10px;
  width: 200px;
  height: 40px;
  padding: 0px;
  font-weight: 600;
  font-size: 16px !important;
}
.only_mobile {
  display: none;
}
.menuMobile {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 22px;
}
.pasekMenuMobile {
  display: none !important;
}
#notActiveMenuMobile {
  height: 0px !important;
  overflow: hidden;
  transition: all 500ms ease-in-out;
}
#activeMenuMobile {
  height: fit-content;
}
.admin#activeMenuMobile {
  height: fit-content;
}
#activeMenuMobile .sidebar {
  height: fit-content;
}

.noOverflowX {
  overflow-x: hidden;
}

h4.title {
  font-size: 20px;
  padding: 20px;
  font-weight: 600;
}
.content {
  background: white;
  overflow-x: hidden;
}
.visibleUploadButton {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .checkboxes .radioButton {
    width: 15px !important;
    border-radius: 50% !important;
    height: 30px !important;
  }
  body .addChild form .Container-sc-crzztr-0 {
    width: calc(100% - 40px) !important;
  }
  .addChild form {
    padding: 15px;
  }
  #loginPAGE .groupIcon,
  .addChild .groupIcon {
    padding: 0;
  }
  #maciejForm .mainH1 {
    font-size: 26px;
    margin-bottom: 0;
  }
  #maciejForm form {
    border: none;
  }
  #loginPAGE form,
  #maciejForm form {
    max-width: 90vw;
    padding: 15px;
    text-align: center;
  }
  #loginPAGE form input {
    max-width: 70vw;
  }
  #loginPAGE .input-group {
    flex-wrap: nowrap !important;
  }
  #loginPAGE form h1,
  #loginPAGE form h3 {
    text-align: center !important;
  }
  .input-group {
    flex-wrap: nowrap;
  }
  .filters input {
    width: 80vw;
  }
  body .no_mobile {
    display: none !important;
  }
  .only_mobile {
    display: block;
  }
  .leftborder {
    border: none;
  }
  .filters {
    flex-wrap: wrap;
    gap: 10px;
  }
  #header h1 {
    margin-top: 20px;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
  }
  body .mobileHEADER {
    align-items: center !important;
    justify-content: center !important;
  }
  .sectionsSwap {
    flex-wrap: wrap;
  }
  .itemSwap {
    width: 50%;
  }
  .pasekMenuMobile {
    display: flex !important;
    height: 80px;
    align-items: center;
    justify-content: space-between;
  }
  #contentPANEL {
    height: unset;
  }
  .noChildren {
    text-align: center;
    min-height: 30vh;
  }
  .visibleUploadButton {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
  }
}
